import './Styles/volunteer.css';
import Nav from './Components/Nav';
import Footer from './Components/Footer';
import Form from './Media/Forms/CSFP_Volunteer_Application.pdf';
import { useState } from 'react';
import { IoIosCloseCircleOutline } from "react-icons/io";
import { FaQuoteLeft } from "react-icons/fa";
import { FaQuoteRight } from "react-icons/fa";
import { RxAvatar } from "react-icons/rx";

export default function Volunteer () {
    const testimonials = [
        {
            "quote":"Volunteering at Salsa on St Clair was incredibly rewarding for me. Meeting and working alongside staff and other volunteers, helping to make the event run smoothly was fun and ultimately made for a weekend of constant excitement. A memorable moment from Salsa was the opportunity I had to be involved in the parade. Seeing upfront the vibrant atmosphere and energy surrounding the parade and being able to contribute to that was uplifting. As a volunteer on social media assistance, I was welcomed with open arms and made to feel integral to making the event run smoothly. The support and guidance from the staff added an extra layer of value to my experience. I deeply appreciate this opportunity as it was an unforgettable experience, and I would eagerly volunteer again next year.",
            "name":"Gian Paolo Sellitto",
            "year":"2023"
        },
        {
            "quote":"I’m really thankful to TD Salsa team for giving me this opportunity to be a part of such a beautiful event. I thoroughly enjoyed myself during the event and feeling grateful to get the opportunity to meet and work with so many amazing people. Will definitely be a part of this event for next year as well.",
            "name":"Sahil Bansal",
            "year":"2023"
        },
        {
            "quote":"It was the best event that I have ever attended in Toronto so far, unforgettable memory and I met new friends during the event. Also I learned a lot working at back stage, get the opportunity to talk to some performers, learned about how showbiz and entertainment industry run in a real life. I would like to thanks the Volunteer coordinators, Ingrid and Katarina for giving me the opportunity to volunteer at the Salsa festival",
            "name":"Deasy Wang",
            "year":"2018"
        },
        {
            "quote":"Volunteering at Salsa on St.Clair was a great experience and I am truly happy that I decided to participated. I met some amazing people and had the chance to work along side professionals that were helpful and supportive. The staff made sure that all volunteers were well taken care of and most importantly having fun! I came into this experience without knowing what to expect, and now I can honestly say that it is a great time and an experience like no other. Most importantly, I will definitely make sure to return for the following years to come.",
            "name":"Michelle Castillo",
            "year":"2016"
        },
        {
            "quote":"I had a wonderful summer by working with TLN, I’ve enjoyed every moment at, Ontario Place , Salsa on St Clair, and Latinfest. I also had the opportunity to watch a training of the Real Madrid soccer team. That was amazing! What I liked the most was working with a good co-operative team in every event. THANKS A LOT!",
            "name":"Adriany M",
            "year":"2009"
        },
        {
            "quote":"I had a great experience volunteering with the TLN summer event crew, since I’ve been studying Spanish it was a great opportunity to make new friends within the Hispanic community and learn more about Hispanic culture.",
            "name":"Tracy C",
            "year":"2006"
        },
        {
            "quote":"Great experience! Team work, meeting awesome people and having a party all at the same time!",
            "name":"Giovanni M",
            "year":"2005"
        },
    ];

    const [isFormOpen, setFormState] = useState(false);

    const openForm = () => {
        setFormState(prevState => !prevState);
    }

    return (
        <>
            <Nav />
            <div className='volunteer-page-banner'></div>
            <section className='page-content'>
                <h1 className="title">TD Salsa In Toronto Festival Volunteer Opportunity</h1>
                <h2 className="sub-title" >Saturday July 5 and Sunday July 6</h2>
                <p className='volunteer-description'>Every year we look for bright, enthusiastic and capable individuals to volunteer their time for any of the events that take place during the TD Salsa in Toronto Festival.</p>

                <p className='volunteer-description'>The TD Salsa in Toronto Festival is committed to helping you have a fun and rewarding experience giving you an opportunity to contribute to your community, develop your skills and qualifications, make new friends, and enjoy the celebration of the Latino culture!</p>
                
                <h1 className="title">We are currently offering a variety of exciting positions:</h1>
                <div className='volunteer-bullet-wrapper'>
                    <ul className='bullet-list'>
                        <li>Artist Registration Coordinator</li>
                        <li>Back Stage Hands</li>
                        <li>Event Coordinator Assistants</li>
                        <li>VIP Patio Host</li>
                        <li>Logistics (Set Up and Tear Down) Crew Member</li>
                    </ul>
                </div>
                <h1 className="title">In recognition of your participation you will:</h1>
                <div className='volunteer-bullet-wrapper'>
                    <ul className='bullet-list'>
                        <li>Enhance your skills and qualifications with hands-on experience in various event organization and marketing areas</li>
                        <li>Be provided complimentary meals and refreshments during the event</li>
                        <li>Receive an invitation to the Volunteer Appreciation Event</li>
                        <li>Gain community service hours</li>
                        <li>Receive a Letter of Reference (upon request)</li>
                        <li>Network with your local community and make new friends</li>
                        <li>Get an exclusive Salsa In Toronto gift bag</li>
                    </ul>
                </div>
                <h1 className="title">TD Salsa In Toronto Festival Requirements</h1>
                <div className='volunteer-bullet-wrapper'>
                    <ul className='bullet-list'>
                        <li>Volunteer your time for a minimum of 6 hours throughout the festival</li>
                        <li>Attend a volunteer orientation meeting</li>
                        <li>Respectfully perform assigned duties as required</li>
                    </ul>
                </div>

                <h1 className="title">Ready to join our team?</h1>
                <p className='volunteer-description'>If you are interested in participating as a volunteer at our festival, download a copy of our application form and send it to <strong>volunteer@salsaintoronto.com</strong> with copy of your resume.</p>
                <a className='volunteer-application-button' href={Form} download="CSFP Volunteer Application">Download Application</a>
            

            </section>
            <section id='testimonial-section'>
                <h1 className="title" id="testimonial-title">Testimonials</h1>
                    <div className='testimonials-viewport'>
                        <div className='testimonials-wrapper'>
                            {testimonials.map((testimonial, index) => (
                                <Testimonial 
                                    quote={testimonial.quote}
                                    name={testimonial.name}
                                    year={testimonial.year}
                                />
                            ))}
                        </div>
                    </div>
            </section>
            <Footer />
        </>

    )
}

function Testimonial (params) {
    return (
        <div className='testimonial-wrapper' key={params.name}>
            <div className='testimonal-quote-wrapper'>
                <FaQuoteLeft className='left-quote'/>
                <p>{params.quote}</p>
                <FaQuoteRight className='right-quote'/>
                <div className='wave' id="wave-1"></div>
            </div>
            <div className='testimonial-name-wrapper'>
                <div className='wave' id="wave-2"></div>
                <RxAvatar className='avatar'/>
                <h1>{params.name}</h1>
                <h1>{params.year}</h1>
            </div>
        </div>
    )
}